import { Dispatch, FC, SetStateAction } from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "../ui/button";
import { IOption } from "@/types/IOption";

interface BatchActionsProps {
  setAction: Dispatch<SetStateAction<string>>;
  handleAction: () => void;
  actionOptions: IOption[];
}

export const BatchActions: FC<BatchActionsProps> = ({
  setAction,
  actionOptions,
  handleAction,
}) => {
  return (
    <div className="flex items-center gap-3">
      <span className="font-bold">Acciones por lote:</span>
      <Select defaultValue={actionOptions[0]?.value} onValueChange={setAction}>
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder="Acciones por lote" />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel className="font-bold">Acciones por lote</SelectLabel>
            {actionOptions.map((option) => (
              <SelectItem value={option.value} key={option.value}>
                {option.text}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
      <Button variant="secondary" type="button" onClick={handleAction}>
        Aplicar acción
      </Button>
    </div>
  );
};
