import React, { useState, useEffect, FC, useContext } from "react";

import { TitleSection } from "../TitleSection";
import { useMutation, useQuery } from "@tanstack/react-query";
import ProducerService from "../../services/producerService";
import {
  IFilterProducerRequest,
  IProducersResponse,
  IProducerResponse,
} from "../../types/IProducer";
import { CardProducer } from "./card/CardProducer";
import { AuthContext } from "../../context/AuthContext";
import Restricted from "../../context/Restricted";
import { StateContext } from "../../context/StateProvider";

const defaultResult = {
  total: 0,
  pages: 0,
  rows: [],
};
const defaultFilters = {
  status: "all",
  client_id: "",
};

type propsTable = {
  handleCreateorEdit: (id: string) => void;
  handleSelectItem: (data: IProducerResponse) => void;
};

export const ListProducers: FC<propsTable> = ({
  handleCreateorEdit,
  handleSelectItem,
}) => {
  const { user } = useContext(AuthContext);
  const { state, dispatch } = useContext(StateContext);
  const [result, setResult] = useState<IProducersResponse>(defaultResult);
  const [filters, setFilters] =
    useState<IFilterProducerRequest>(defaultFilters);
  const [rows, setRows] = useState<Array<IProducerResponse>>([]);

  const getItems = useMutation((filters: IFilterProducerRequest) =>
    ProducerService.getAll(filters)
  );

  useEffect(() => {
    if (user.id) {
      handleFilter(filters);
    }
  }, [user.id]);

  const handleFilter = async (filtersRequest: IFilterProducerRequest) => {
    let fil = { ...filtersRequest, client_id: user.id };
    await setFilters(fil);
    getItems.mutateAsync(fil).then((res) => {
      if (user.user_role !== "producer") {
        const result = [];
        for (const item of user.permitted_producers) {
          const finded = res.rows.find((r) => r.id == item.id);
          if (finded) result.push(finded);
        }
        setRows(result);
      } else {
        setRows(res.rows);
      }
    });
  };

  const itemsProps = {
    handleSelectItem,
    handleFilter,
  };

  return (
    <div>
      <div className="relative">
        <TitleSection>Productoras</TitleSection>
        <Restricted to="admin">
          {user.user_role != "coproducer" && (
            <div className="absolute -top-0 right-0 ">
              <button
                onClick={() => handleCreateorEdit("")}
                className="rounded-full border-[2px] border-black-100 px-12 py-2 font-bold text-black-100 hover:bg-black-100 hover:text-white"
              >
                Crear nueva productora
              </button>
            </div>
          )}
        </Restricted>
      </div>
      <div className="mb-16 mt-6 grid gap-2 lg:grid-cols-2 2xl:grid-cols-3">
        {rows.length ? (
          rows.map((item, index) => (
            <div key={index}>
              <CardProducer {...itemsProps} data={item} />
            </div>
          ))
        ) : (
          <p className="text-center">No existen productoras disponibles</p>
        )}
      </div>
    </div>
  );
};
