import { IOption } from "@/types/IOption";

export const sortOptions: IOption[] = [
  {
    value: "createdAt",
    text: "Fecha de publicación",
  },
  {
    value: "event_name",
    text: "Evento",
  },
  {
    value: "status",
    text: "Estado publicación",
  },
  {
    value: "trader_name",
    text: "Trader",
  },
  {
    value: "producer_name",
    text: "Productora",
  },
  {
    value: "total",
    text: "Precio",
  },
];
