import { Link } from "react-router-dom";
import { ColumnDef } from "@tanstack/react-table";

import { formatDate } from "@/utils/format-date";
import { formatMoney } from "@/utils/format-money";
import { getBgColorFromValue, getOfferStatusFromValue } from "@/utils/status";
import { IOffersResponse } from "../../types/IOffer";
import { DataTableRowActions } from "./DataTableRowActions";
import { Checkbox } from "@/components/ui/checkbox";

interface offersColumnsProps {
  onEditPrice?: (id: string) => void;
  onDelete?: (id: string) => void;
  onSeeTraceability?: (order_ticket_id: string) => void;
}

export const getOffersColumns = ({
  onEditPrice,
  onDelete,
  onSeeTraceability,
}: offersColumnsProps): ColumnDef<IOffersResponse>[] => {
  const getOfferUrl = (id: string) => `/admin/offers/${id}`;

  return [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            (table.getIsSomePageRowsSelected() && "indeterminate")
          }
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
    },
    {
      accessorKey: "event_name",
      header: "Evento",
      cell: ({ row }) => {
        const { id } = row.original;
        return <Link to={getOfferUrl(id)}>{row.original.event_name}</Link>;
      },
    },
    {
      accessorKey: "producer_name",
      header: "Productora",
      cell: ({ row }) => {
        const { id } = row.original;
        return <Link to={getOfferUrl(id)}>{row.original.producer_name}</Link>;
      },
    },
    {
      accessorKey: "order_ticket_id",
      header: "Order Ticket",
      cell: ({ row }) => {
        const { id, order_ticket_id } = row.original;
        return <Link to={getOfferUrl(id)}>{`#${order_ticket_id}`}</Link>;
      },
    },
    {
      accessorKey: "createdAt",
      header: "Fecha de Publicación",
      cell: ({ row }) => {
        const { id } = row.original;
        return (
          <Link to={getOfferUrl(id)}>{formatDate(row.original.createdAt)}</Link>
        );
      },
    },
    {
      accessorKey: "subtotal",
      header: "Subtotal",
      cell: ({ row }) => {
        const { id } = row.original;
        return (
          <Link to={getOfferUrl(id)}>{formatMoney(row.original.subtotal)}</Link>
        );
      },
    },
    {
      accessorKey: "service",
      header: "Recargo",
      cell: ({ row }) => {
        const { id } = row.original;
        return (
          <Link to={getOfferUrl(id)}>{formatMoney(row.original.service)}</Link>
        );
      },
    },
    {
      accessorKey: "total",
      header: "Total",
      cell: ({ row }) => {
        const { id } = row.original;
        return (
          <Link to={getOfferUrl(id)}>{formatMoney(row.original.total)}</Link>
        );
      },
    },
    {
      accessorKey: "ticket_name",
      header: "Ticket",
      cell: ({ row }) => {
        const { id } = row.original;
        return <Link to={getOfferUrl(id)}>{row.original.ticket_name}</Link>;
      },
    },
    {
      accessorKey: "trader_name",
      header: "Trader",
      cell: ({ row }) => {
        const { id } = row.original;
        return (
          <Link to={getOfferUrl(id)}>
            <strong>{row.original.trader_name}</strong>
          </Link>
        );
      },
    },
    {
      accessorKey: "status",
      header: "Estado Publicación",
      cell: ({ row }) => {
        const { id } = row.original;
        return (
          <Link
            to={getOfferUrl(id)}
            className="flex items-center justify-center gap-3 rounded-full border-[1px] border-colorBorder px-5  py-2 text-center "
          >
            <div
              className={`rounded-full ${getBgColorFromValue(
                row.getValue("status")
              )} p-[.35rem]`}
            />
            <p className="capitalize">
              {getOfferStatusFromValue(row.getValue("status"))}
            </p>
          </Link>
        );
      },
    },
    {
      id: "actions",
      cell: ({ row }) => {
        const { id, order_ticket_id } = row.original;
        return (
          <DataTableRowActions
            row={row}
            onEditPrice={onEditPrice!.bind(null, id)}
            onDelete={onDelete!.bind(null, id)}
            onSeeTraceability={onSeeTraceability!.bind(null, order_ticket_id)}
          />
        );
      },
    },
  ];
};
