import { FC, Dispatch, SetStateAction } from "react";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { IFilterRequest } from "../../types/IFilter";
import { updateUrl } from "../../utils/updateUrl";
import config from "../../config/variables";

type filterPage = {
  filters: IFilterRequest;
  setFilters: Dispatch<SetStateAction<IFilterRequest>>;
};

export const FilterQuantity: FC<filterPage> = ({ filters, setFilters }) => {
  const { limit = config.filter_default_limit } = filters;

  const handleSelectChange = (newLimit: string) => {
    updateUrl({ page: 1, limit: +newLimit, query: filters.query });
    setFilters({ ...filters, limit: Number(newLimit), page: 1 });
  };

  return (
    <Select defaultValue={limit.toString()} onValueChange={handleSelectChange}>
      <SelectTrigger className="w-[180px]">
        <SelectValue placeholder="Cant. por página" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>Cant. por página</SelectLabel>
          <SelectItem value="5">5 por página</SelectItem>
          <SelectItem value="10">10 por página</SelectItem>
          <SelectItem value="20">20 por página</SelectItem>
          <SelectItem value="50">50 por página</SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};
