import { Dispatch, FC, SetStateAction } from "react";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { IFilterRequest } from "@/types/IFilter";
import { updateUrl } from "@/utils/updateUrl";
import { IOption } from "@/types/IOption";

type filterSortProps = {
  filters: IFilterRequest;
  setFilters: Dispatch<SetStateAction<IFilterRequest>>;
  sortOptions: IOption[];
};

export const FilterSort: FC<filterSortProps> = ({
  filters,
  setFilters,
  sortOptions,
}) => {
  const handleSortByChange = (newSortBy: string) => {
    updateUrl({
      page: 1,
      limit: filters.limit,
      query: filters.query,
      sortBy: newSortBy,
    });
    setFilters({ ...filters, sortBy: newSortBy, page: 1 });
  };

  const handleSortOrderChange = (newSortOrder: string) => {
    updateUrl({
      page: 1,
      limit: filters.limit,
      query: filters.query,
      sortBy: filters.sortBy,
      sortOrder: newSortOrder,
    });
    setFilters({ ...filters, sortOrder: newSortOrder, page: 1 });
  };

  return (
    <div className="mt-3 flex items-center gap-3 xl:mt-0 xl:justify-end">
      <span className="font-bold">Ordenar por:</span>
      <Select defaultValue={filters.sortBy} onValueChange={handleSortByChange}>
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder="Ordenar por" />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel className="font-bold">Ordenar por</SelectLabel>
            {sortOptions.map((option) => (
              <SelectItem value={option.value} key={option.value}>
                {option.text}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>

      <Select
        defaultValue={filters.sortOrder}
        onValueChange={handleSortOrderChange}
      >
        <SelectTrigger className="w-[80px]">
          <SelectValue placeholder="Asc/Desc" />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>Asc/Desc</SelectLabel>
            <SelectItem value="asc">Asc</SelectItem>
            <SelectItem value="desc">Desc</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
};
