import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";

import { Back } from "../../Back";
import { FilterSectionWithNavLink } from "../../table/FilterSectionWithNavLink";
import { BannerEvent } from "../BannerEvent";
import { OrdersEvent } from "./OrdersEvent";
import { TicketsEvent } from "./TicketsEvent";
import { ReportsEvent } from "./ReportsEvent";
import { TeamEvent } from "./TeamEvent";
import { SendNotificationsEvent } from "./SendNotificationsEvent";
import { SendTicketsEvent } from "./SendTicketsEvent";
import { NewEvent } from "../NewEvent/NewEvent";
import { IEventResponse } from "../../../types/IEvent";
import { AuthContext } from "../../../context/AuthContext";
import { SendInvitationsEvent } from "./SendInvitationsEvent";
import { PageContainer } from "../../PageContainer";
import { StateContext } from "../../../context/StateProvider";
import { Multimedia } from "./multimedia/Multimedia";
import { FormValidator } from "../../validators/FormValidator";
import { CreateOrderEvent } from "./CreateOrderEvent";
import { SendInvitationsCSV } from "./csv/SendInvitationsCSV";
import TeamService from "../../../services/teamService";
import EventService from "../../../services/eventService";

type SelectedEventProps = {
  edit?: boolean;
};

export const SelectedEvent = ({ edit = false }: SelectedEventProps) => {
  const { event_id, producer_id } = useParams();
  const [searchParams] = useSearchParams();
  const eventSection = searchParams.get("section");
  const navigate = useNavigate();
  const { state, dispatch } = useContext(StateContext);
  const auth = useContext(AuthContext);
  const { producer } = auth;

  const [editEvent, setEditEvent] = useState(edit);
  const [createEditValidator, setCreateEditValidator] = useState(false);
  const [currentValidator, setCurrentValidator] = useState<string>();
  const [selfData, setSelfData] = useState<IEventResponse>(
    {} as IEventResponse
  );

  const getMembers = useMutation(() =>
    TeamService.getAll({
      producer_event_id: event_id,
      team_role: "leader",
      producer_producer_id: auth.producer?.id,
    })
  );

  const handleReloadEvent = () => {
    getItem.mutateAsync().then((res) => setSelfData(res));
  };

  const getItem = useMutation(() => EventService.get(event_id || ""));

  useEffect(() => {
    loadEvent();
  }, []);

  const loadEvent = () => {
    getItem.mutateAsync().then((res) => {
      setSelfData(res);
      getMembers.mutateAsync().then((res2) => {
        dispatch({ type: "setAvailableMembers", payload: res2.rows });
        dispatch({
          type: "setMembers",
          payload: res?.team_ids?.map((item: any) => {
            return item.team_id;
          }),
        });
      });
    });
  };

  const filterSectionProps = () => {
    let result = [
      { title: "Órdenes", id: "orders" },
      { title: "Tickets", id: "tickets" },
      { title: "Reportes", id: "reports" },
      { title: "Equipo", id: "team" },
      { title: "Validadores", id: "validators" },
      { title: "Envío tickets a Líderes", id: "sendTickets" },
      { title: "Envío de Invitaciones", id: "sendInvitations" },
      { title: "Invitaciones por CSV", id: "sendInvitationsCSV" },
      { title: "Crear Orden", id: "createOrder" },
      { title: "Multimedia", id: "multimedia" },
    ];

    if (auth.permissions.events == true) {
      result = [...result.filter((item) => item.id != "reports")];
    }

    if (["producer", "coproducer"].includes(auth.role)) {
      result = [
        ...result.filter(
          (item) => !["sendNotifications", "createOrder"].includes(item.id)
        ),
      ];
    }

    return result;
  };

  const [formValue, setFormValue] = useState(
    eventSection ? eventSection : "orders"
  );

  const ref = useRef<HTMLFormElement>(null);
  const [hasScrollBar, setHasScrollBar] = useState(false);

  useEffect(() => {
    function updateState() {
      const el = ref.current;
      el &&
        setHasScrollBar(el.scrollWidth > el.getBoundingClientRect().width + 1);
    }

    updateState();

    window.addEventListener("resize", updateState);
    return () => window.removeEventListener("resize", updateState);
  }, [ref]);

  const handleBack = () => {
    if (window.location.pathname.includes("admin/")) {
      const eventsPath = producer_id
        ? `producers/${producer_id}/events`
        : "events";
      navigate(`/admin/${eventsPath}`);
    }
    if (window.location.pathname.includes("client/"))
      navigate(
        `/client/${
          producer && producer.id ? `producers/${producer.id}/` : ""
        }events`
      );
  };

  const handleCreateEditValidator = (id?: string) => {
    console.info("validator id edit ", id);
    if (id) setCurrentValidator(id);
    else setCurrentValidator("");
    setCreateEditValidator(!createEditValidator);
  };

  const teamMembers = selfData?.members?.map((member) => member.user[0]) || [];

  const showFormValue = () => {
    switch (formValue) {
      case "orders":
        return <OrdersEvent data={selfData} />;
      case "tickets":
        return <TicketsEvent id={event_id || ""} data={selfData} />;
      case "reports":
        return selfData?.producer_id ? (
          <ReportsEvent
            event_id={event_id}
            producer_id={selfData?.producer_id ? selfData?.producer_id : "null"}
          />
        ) : (
          <div></div>
        );
      case "team":
        return selfData?.producer_id ? (
          <TeamEvent
            producerId={selfData?.producer_id ? selfData?.producer_id : "null"}
            reloadEvent={handleReloadEvent}
            team_roles={["leader", "seller"]}
          />
        ) : (
          <div></div>
        );
      case "validators":
        return createEditValidator ? (
          <FormValidator
            handleCreateEditValidator={handleCreateEditValidator}
            dataEvent={selfData}
            currentValidatorId={currentValidator}
          />
        ) : selfData?.producer_id ? (
          <TeamEvent
            producerId={selfData?.producer_id ? selfData?.producer_id : "null"}
            reloadEvent={handleReloadEvent}
            team_roles={["validator"]}
            title="Validadores"
            canAssignTeam={false}
            handleCreateEditItem={handleCreateEditValidator}
          />
        ) : (
          <div></div>
        );
      case "sendNotifications":
        return <SendNotificationsEvent eventId={event_id || ""} />;
      case "sendTickets":
        return selfData?.producer_id ? (
          <SendTicketsEvent
            producerId={selfData.producer_id ? selfData.producer_id : "null"}
            eventId={event_id || ""}
          />
        ) : (
          <div></div>
        );
      case "sendInvitations":
        return <SendInvitationsEvent eventId={event_id || ""} />;
      case "sendInvitationsCSV":
        return <SendInvitationsCSV eventId={event_id || ""} />;
      case "createOrder":
        return (
          <CreateOrderEvent
            eventId={event_id || ""}
            teamMembers={teamMembers}
          />
        );
      case "multimedia":
        return <Multimedia eventId={event_id || ""} loadEvent={loadEvent} />;
      default:
        return <OrdersEvent data={selfData} />;
    }
  };

  return (
    <PageContainer>
      {editEvent ? (
        <NewEvent edit={true} data={selfData} setEditEvent={setEditEvent} />
      ) : (
        <div>
          <Back onClick={() => handleBack()} />
          <div className="pt-6">
            <BannerEvent
              edit={true}
              data={selfData}
              setEditEvent={setEditEvent}
              showTeamMembers={true}
            />
          </div>
          <div className="w-full border-b pt-10">
            <FilterSectionWithNavLink
              filterSectionProps={filterSectionProps()}
              formValue={formValue}
              setFormValue={setFormValue}
            />
          </div>
          <div className="pt-10">{showFormValue()}</div>
        </div>
      )}
    </PageContainer>
  );
};
