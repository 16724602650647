import React, { FC, useEffect, useState, useContext } from "react";
import { GoGraph } from "react-icons/go";
import DropdownDots from "../../../FormElement/DropdownDots";
import { motion } from "framer-motion";
import { PictureAndName } from "../../../rrpp/components/PictureAndName/PictureAndName";
import { FilterDataRadio } from "../../../table/FilterDataRadio";
import ISortOption from "../../../../types/ISortOption";
import { IFilterRequest } from "../../../../types/IFilter";
import { useNavigate } from "react-router-dom";
import {
  IReportPublicSaleResponse,
  IReportRequest,
} from "../../../../types/IReport";
import ReportService from "../../../../services/reportService";
import { useQuery, useMutation } from "@tanstack/react-query";
import { formatMoney } from "../../../../utils/format-money";
import moment from "moment/moment";
import EventService from "../../../../services/eventService";
import { StateContext } from "../../../../context/StateProvider";

type reportProps = {
  producer_id?: string;
  event_id?: string;
};

const defaultFilters = {
  page: 1,
  /* limit: 5,
    status: 'all',
    user_role: 'producer',
    sortBy: 'data1',
    sortOrder: 'desc', */
};

export const SelledPublic: FC<reportProps> = ({ producer_id, event_id }) => {
  const navigate = useNavigate();
  const [data, setData] = useState<Array<IReportPublicSaleResponse>>([]);
  const [filters, setFilters] = useState<IFilterRequest>({ ...defaultFilters });
  const [optionsType, setOptionsType] = useState<Array<ISortOption>>([]);
  const [optionsLeader, setOptionsLeader] = useState<Array<ISortOption>>([]);
  const [optionsSeller, setOptionsSeller] = useState<Array<ISortOption>>([]);
  const [filterTableValue, setFilterTableValue] = useState("");
  const [filterTableValueLeader, setFilterTableValueLeader] = useState("");
  const { state, dispatch } = useContext(StateContext);

  const getItems = useMutation((f: IFilterRequest) =>
    ReportService.publicSale({ ...f, page: undefined } as IReportRequest)
  );

  // const getEvent = useMutation(() => EventService.get(event_id || ""));

  const event = useQuery({
    queryKey: ["event", event_id || ""],
    queryFn: () => EventService.get(event_id || ""),
  });

  const applyFilters = async () => {
    let parameters: any = {};
    if (producer_id) parameters.producer_id = producer_id;
    if (event_id) parameters.event_id = event_id;
    await setFilters({ ...filters, ...parameters });
    await getItems.mutateAsync({ ...filters, ...parameters }).then((res) => {
      setData(res);
      ticketOptions(res);
      leaderOptions(res);
      PublicOptions(res);
    });
  };

  useEffect(() => {
    if (event_id) {
      applyFilters();
    }
  }, [event_id]);

  const handleSortSection = async (type: string) => {
    setFilterTableValue(type);
    setFilterTableValueLeader("");
    const paramsFilters = {
      ...filters,
      page: 1,
      type: type,
      user_id: undefined,
      leader_id: undefined,
    };
    await setFilters(paramsFilters);
    await getItems.mutateAsync(paramsFilters).then((res) => setData(res));
  };

  const handleSortSectionUser = async (value: string) => {
    setFilterTableValue(value);
    setFilterTableValueLeader("");
    const paramsFilters = {
      ...filters,
      page: 1,
      type: undefined,
      user_id: value,
      leader_id: undefined,
    };
    await setFilters(paramsFilters);
    await getItems.mutateAsync(paramsFilters).then((res) => setData(res));
  };

  const handleSortSectionLeader = async (value: string) => {
    setFilterTableValueLeader(value);
    setFilterTableValue("");
    const paramsFilters = {
      ...filters,
      page: 1,
      type: undefined,
      user_id: undefined,
      leader_id: value,
    };
    await setFilters(paramsFilters);
    await getItems.mutateAsync(paramsFilters).then((res) => setData(res));
  };

  const defaultFilterAll = { value: "", text: "TODOS" } as ISortOption;
  const PublicOptions = (dataIn: IReportPublicSaleResponse[]) => {
    const newOptions = dataIn.map((e) => {
      return {
        value: e.id,
        text: e.first_name + " " + e.last_name,
        sortOrder: "asc",
      };
    });
    setOptionsSeller(newOptions);
  };
  const filteredData = data?.filter((e) => e.leader);

  const leaderOptions = (dataIn: IReportPublicSaleResponse[]) => {
    const filteredData = dataIn.filter((e) => e.leader);
    const leaderOptions: Array<ISortOption> = filteredData?.map((e) => {
      return {
        value: e.leader?._id,
        text: e.leader?.first_name + " " + e.leader?.last_name,
        sortOrder: "asc",
      };
    });
    setOptionsLeader(leaderOptions);
  };
  const ticketOptions = (op: IReportPublicSaleResponse[]) => {
    const result: Array<ISortOption> = [];
    op.map((e: any) => {
      for (const t of e.order_tickets) {
        if (!result.find((r: any) => r.value == t.ticket.type)) {
          result.push({
            value: t.ticket.type,
            text: t.ticket.type,
            sortOrder: "asc",
          });
        }
      }
    });
    result.push({
      value: "is_free",
      text: "Envio Free",
      sortOrder: "asc",
    });
    setOptionsType(result);
  };
  const getCsv = useMutation(() =>
    ReportService.getCsvPublicSale({ ...filters, page: undefined })
  );
  const handleCsv = async () => {
    if (!event.data) {
      dispatch({
        type: "toastError",
        payload: "Elegí un evento primero.",
      });
      return;
    }

    try {
      await getCsv.mutateAsync().then((response) => {
        const nameFile = `VENTA_DE_PUBLICAS-${event.data.name}-${event.data.producer.name}.csv`;
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", nameFile);
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {
      dispatch({
        type: "toastError",
        payload: "No pudimos generar el CSV.",
      });
    }
  };
  return (
    <div className="rounded-xl outline outline-2 outline-colorBorder 2xl:h-full ">
      <div className="flex h-16 items-center justify-between border-b border-colorBorder px-10">
        <h3 className="text-xl font-bold">Venta de públicas</h3>
        <div className="flex gap-5">
          <button
            className="flex items-center justify-center"
            onClick={handleCsv}
          >
            <GoGraph className="mr-1" />
            <span className="text-xs font-bold">Exportar a CSV</span>
          </button>
        </div>
      </div>

      <table className="my-table-spacing  w-full border-separate border-spacing-y-2 gap-5 overflow-x-auto px-6 text-left">
        <thead className="divide-solid border">
          <tr className="pb-4 text-sm font-bold text-gray-100">
            <td className="py-2">
              <FilterDataRadio
                setSort={handleSortSectionUser}
                options={[defaultFilterAll, ...optionsSeller]}
                title="Pública"
                externalCheckedValue={filterTableValue}
              />
            </td>
            <td className="py-2">
              <FilterDataRadio
                setSort={handleSortSectionLeader}
                options={[defaultFilterAll, ...optionsLeader]}
                title="Team Leader"
                externalCheckedValue={filterTableValueLeader}
              />
            </td>
            <td className="py-4 pl-4">Mail</td>
            <td className="py-2">
              <FilterDataRadio
                setSort={handleSortSection}
                options={[defaultFilterAll, ...optionsType]}
                title="Tipo de entrada"
                externalCheckedValue={filterTableValue}
              />
            </td>
            <td className="py-4 pl-4">Precio unitario</td>
            <td className="py-4 pl-4">Cantidad</td>
            <td className="py-4 pl-4">Total</td>
            {/*<td className=""></td>*/}
          </tr>
        </thead>
        <tbody>
          {event_id &&
            data.map((row) =>
              row.order_tickets.map((subrow) => (
                <motion.tr
                  key={subrow.id}
                  layout
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  initial={{ opacity: 0.2 }}
                  transition={{ type: "spring", stiffness: 100, bounce: 0 }}
                  className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
                >
                  <td className="pl-4 text-sm font-extrabold">
                    <PictureAndName
                      profilePicture=""
                      firstName={row?.first_name}
                      lastName={row?.last_name}
                      status={row?.status}
                    />
                  </td>
                  <td className="pl-4 text-sm font-normal">
                    {row.leader
                      ? row.leader.first_name + " " + row.leader.last_name
                      : ``}
                    {row.leader?.status == "deleted" && (
                      <small className="font-light"> (eliminado)</small>
                    )}
                  </td>
                  <td className="pl-4 text-sm font-normal">{row.email}</td>
                  <td className="pl-4 text-sm font-normal capitalize">
                    {subrow.ticket?.type}
                    {subrow.ticket?.name && (
                      <strong> ({subrow.ticket?.name})</strong>
                    )}
                  </td>
                  <td className="pl-4 text-sm font-normal">
                    {formatMoney(subrow.ticket?.price)}
                  </td>
                  <td className="pl-4 text-sm font-normal">{subrow?.qty}</td>
                  <td className="pl-4 text-sm font-bold">
                    {formatMoney(subrow?.qty * subrow?.ticket?.price)}
                  </td>
                  {/*<td>*/}
                  {/*    <DropdownDots options={[*/}
                  {/*        {title: 'Editar', action: ()=>handleEdit(row.id), icon: (<FaEdit size={23} />)},*/}
                  {/*        {title: 'Eliminar', action: ()=>handleDelete(row.id), icon: (<FaTrashAlt size={23} />)}*/}
                  {/*    ]}/>*/}
                  {/*</td>*/}
                </motion.tr>
              ))
            )}
        </tbody>
      </table>
    </div>
  );
};
