import { ChangeEvent, Dispatch, SetStateAction } from "react";

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormDescription,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { IOffersResponse } from "@/types/IOffer";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { editPriceSchema } from "./EditPriceSchema";
import { formatMoney, roundToTwoDecimals } from "@/utils/format-money";
import { servicePercentage } from "./constants/ServicePercentage";

interface DialogEditPriceProps {
  handleCloseModal: () => void;
  newSubtotal: number | undefined;
  setNewSubtotal: Dispatch<SetStateAction<number | undefined>>;
  newTotal: number | undefined;
  setNewTotal: Dispatch<SetStateAction<number | undefined>>;
  newService: number | undefined;
  setNewService: Dispatch<SetStateAction<number | undefined>>;
  isDialogOpen: boolean;
  setIsDialogOpen: Dispatch<SetStateAction<boolean>>;
  offer: IOffersResponse;
  handleEditPrice: () => Promise<void>;
}

type formDefaultValues = {
  total: number | null;
  subtotal: number | null;
};

const defaultValues: formDefaultValues = {
  total: null,
  subtotal: null,
};

export const DialogEditPrice = ({
  handleCloseModal,
  newSubtotal,
  setNewSubtotal,
  newTotal,
  setNewTotal,
  newService,
  setNewService,
  isDialogOpen,
  setIsDialogOpen,
  offer,
  handleEditPrice,
}: DialogEditPriceProps) => {
  const isNewSubtotalValid = !!(
    offer &&
    newSubtotal &&
    newSubtotal >= offer.ticket_price
  );

  const form = useForm<formDefaultValues>({
    resolver: yupResolver(editPriceSchema),
    defaultValues: defaultValues,
  });

  const handleChangeSubtotal = (evt: ChangeEvent<HTMLInputElement>) => {
    const enteredSubtotal = roundToTwoDecimals(+evt.target.value);
    const total = roundToTwoDecimals(
      +evt.target.value / (1 - servicePercentage)
    );
    setNewSubtotal(enteredSubtotal);
    setNewTotal(total);
    setNewService(roundToTwoDecimals(total - enteredSubtotal));
  };

  const handleChangeTotal = (evt: ChangeEvent<HTMLInputElement>) => {
    const enteredTotal = roundToTwoDecimals(+evt.target.value);
    const service = roundToTwoDecimals(+evt.target.value * servicePercentage);
    setNewTotal(enteredTotal);
    setNewService(service);
    setNewSubtotal(roundToTwoDecimals(enteredTotal - service));
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogContent
        onCloseAutoFocus={handleCloseModal}
        className="text-center"
      >
        <DialogHeader>
          <DialogTitle className="text-center text-3xl">
            Editar Precio
          </DialogTitle>
        </DialogHeader>

        {!isNewSubtotalValid && (
          <p className="mt-[-10px] text-sm text-red">
            El precio debe ser mayor a {formatMoney(offer.ticket_price)} (precio
            original del ticket)
          </p>
        )}

        <Form {...form}>
          <form className="space-y-8">
            <div className="grid grid-cols-3">
              <FormItem>
                <FormLabel>Subtotal</FormLabel>
                <FormControl>
                  <Input
                    type="number"
                    className="h-9 w-full"
                    placeholder="Subtotal..."
                    value={newSubtotal}
                    onChange={handleChangeSubtotal}
                  />
                </FormControl>
                <FormDescription>Lo que recibe el vendedor</FormDescription>
                <FormDescription>
                  Subtotal actual
                  <br />
                  {formatMoney(offer.subtotal)}
                </FormDescription>
                <FormMessage />
              </FormItem>

              <FormItem>
                <FormLabel>Servicio</FormLabel>
                <p className="grid h-9 place-items-center">
                  {formatMoney(newService ?? 0)}
                </p>
                <FormDescription>Comisión BOMBO</FormDescription>
                <FormDescription>
                  Servicio actual
                  <br />
                  {formatMoney(offer.service)}
                </FormDescription>
                <FormMessage />
              </FormItem>

              <FormItem>
                <FormLabel>Total</FormLabel>
                <FormControl>
                  <Input
                    type="number"
                    className="h-9 w-full"
                    placeholder="Total..."
                    value={newTotal}
                    onChange={handleChangeTotal}
                  />
                </FormControl>
                <FormDescription>Lo que paga el comprador</FormDescription>
                <FormDescription>
                  Total actual
                  <br />
                  {formatMoney(offer.total)}
                </FormDescription>
                <FormMessage />
              </FormItem>
            </div>

            <Button
              type="button"
              disabled={!isNewSubtotalValid}
              onClick={handleEditPrice}
            >
              Confirmar edición
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
