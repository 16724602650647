import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import * as yup from "yup";

import { Title } from "../../Title";
import { PageContainer } from "../../../PageContainer";
import FormDatePicker from "../../../FormElement/FormInput";
import { Back } from "../../../Back";
import Option from "./Option";
import Check from "./Check";
import SelectMultiple from "./SelectMultiple";
import { StateContext } from "../../../../context/StateProvider";
import TicketService from "../../../../services/ticketService";
import EventService from "../../../../services/eventService";

type ExportCsvFormShape = {
  tickets: { value: string; label: string }[];
  columns: {
    buyer_first_name: string;
    buyer_last_name: string;
  };
  status: { value: string; label: string }[];
  start_date: string;
  end_date: string;
  remember_fields: string;
  export_itemized: string;
};

const availableOrderStatus = [
  { label: "Approved", value: "approved" },
  { label: "Pending", value: "pending" },
  { label: "Refunded", value: "refunded" },
  { label: "Rejected", value: "rejected" },
];

const availableColumns = [
  { label: "Buyer First Name", value: "buyer_first_name" },
  { label: "Buyer Last Name", value: "buyer_last_name" },
  { label: "Buyer Email", value: "buyer_email" },
  { label: "Buyer Phone Number", value: "buyer_phone_number" },
  { label: "Buyer Dni", value: "buyer_dni" },
  { label: "Event Name", value: "event_name" },
  { label: "Order Id", value: "order_id" },
  { label: "Order Status", value: "order_status" },
  { label: "Order Total", value: "order_total" },
  { label: "Order Service Charge", value: "order_service_charge" },
  { label: "Order External Reference", value: "order_external_reference" },
  { label: "Ticket Name", value: "ticket_name" },
  { label: "Ticket Type", value: "ticket_type" },
  { label: "Ticket Price", value: "ticket_price" },
  { label: "Ticket Id", value: "ticket_id" },
  { label: "Payment Method", value: "payment_method" },
  { label: "Payment Date", value: "payment_date" },
  { label: "Seller First Name", value: "seller_first_name" },
  { label: "Seller Last Name", value: "seller_last_name" },
  { label: "Seller ID", value: "seller_id" },
  { label: "Team Lead First Name", value: "team_lead_first_name" },
  { label: "Team Lead Last Name", value: "team_lead_last_name" },
];

const ExportCsv = () => {
  const navigate = useNavigate();
  const { event_id } = useParams();
  const { dispatch } = useContext(StateContext);

  const form = useForm<ExportCsvFormShape>({
    resolver: yupResolver(
      yup.object().shape({
        tickets: yup
          .array()
          .min(1, "Select at least one element")
          .required("Select at least one element"),
        status: yup
          .array()
          .min(1, "Select at least one element")
          .required("Select at least one element"),
        columns: yup.object().test({
          name: "columns",
          message: "Select at least one option",
          test: (value: object) =>
            Object.values(value).some((x) => x === "true"),
        }),
      })
    ),
    mode: "all",
  });

  const handlePersistColumns = (flag: boolean, columns: object) => {
    if (flag) {
      localStorage.setItem(
        "export-csv/selected-columns",
        JSON.stringify({ columns, remember_fields: "true" })
      );
    } else {
      localStorage.removeItem("export-csv/selected-columns");
    }
  };

  const { data: tickets } = useQuery(
    ["tickets", event_id],
    async () => TicketService.getAll({ event_id }),
    {
      onError: () =>
        dispatch({
          type: "toastError",
          payload: "Error fetching event tickets",
        }),
    }
  );

  const { data: event } = useQuery(
    ["event", event_id],
    async () => EventService.get(event_id || ""),
    {
      onError: () =>
        dispatch({
          type: "toastError",
          payload: "Error fetching event",
        }),
    }
  );

  const handleSubmit = async (values: ExportCsvFormShape) => {
    if (!event) return;

    handlePersistColumns(values.remember_fields === "true", values.columns);
    const requestedColumns = Object.entries(values.columns)
      .map(([key, value]) => value && key)
      .filter(Boolean);
    const requestedStatuses = values.status?.map(({ value }) => value);
    const requestedTickets = values.tickets?.map(({ value }) => value);
    const exportItemized = values.export_itemized === "true";

    try {
      const response = await EventService.getCsvOrderticketsPurchase({
        ...values,
        columns: requestedColumns,
        status: requestedStatuses,
        tickets: requestedTickets,
        export_itemized: exportItemized,
      });

      const blob = new Blob([response], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${event.name} ${moment(new Date()).format(
        "DD-MM-YYYY_hh:mm:ss"
      )}.csv`;
      link.click();
      URL.revokeObjectURL(link.href);
      link.remove();
    } catch (e: unknown) {
      const error = e as { response: { data: { message: string | string[] } } };
      if (Array.isArray(error.response.data.message)) {
        error.response.data.message.forEach((message) =>
          dispatch({
            type: "toastError",
            payload: message,
          })
        );
        return;
      }

      dispatch({
        type: "toastError",
        payload: error.response.data.message,
      });
    }
  };

  const loadPersistedColumns = () => {
    const localStorageState = localStorage.getItem(
      "export-csv/selected-columns"
    );
    if (localStorageState) {
      const parsedState = JSON.parse(localStorageState);
      form.reset(parsedState);
    }
  };

  useEffect(() => {
    loadPersistedColumns();
  }, []);

  return (
    <PageContainer>
      <span className="block pb-6">
        <Back onClick={() => navigate(-1)} />
      </span>
      <Title>Exportar CSV</Title>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <Option
            title="Ticket Type"
            description="Select a ticket type you wish to export CSV file for"
          >
            <SelectMultiple
              allowSelectAll
              name="tickets"
              options={tickets?.rows.map(({ id, name }) => ({
                value: id,
                label: name,
              }))}
            />
          </Option>
          <Option
            title="Date range"
            description="Select a date range or a single date to export CSV for"
          >
            <span className="flex gap-7 [&>*]:mb-0 [&>*]:w-fit">
              <FormDatePicker
                name="start_date"
                type="date"
                label="From"
                min={moment("05/01/2023").utcOffset(0).format("YYYY-MM-DD")}
              />
              <FormDatePicker
                name="end_date"
                type="date"
                label="To"
                min={moment("05/01/2023").utcOffset(0).format("YYYY-MM-DD")}
                max={moment(new Date()).utcOffset(0).format("YYYY-MM-DD")}
              />
            </span>
          </Option>
          <Option
            title="Show Columns"
            description="Select which information to include in the CSV"
          >
            <div className="flex flex-col gap-3">
              {availableColumns.map(({ label, value }, i) => (
                <Check
                  key={`${i}#${value}`}
                  name={`columns.${value}`}
                  options={[{ value: "true", label }]}
                />
              ))}
              <p className="text-red-600 dark:text-red-500 text-sm text-red">
                {form.formState.errors.columns?.message ?? ""}
              </p>
            </div>
          </Option>
          <Option
            title="Order Status"
            description="Select order with only certain order status or export all of the orders"
          >
            <SelectMultiple
              allowSelectAll
              name="status"
              options={availableOrderStatus}
            />
          </Option>
          <Option title="Exportar ordenes desglosadas (para ticketera)">
            <div className="flex flex-col gap-3">
              <Check
                name="export_itemized"
                options={[{ value: "true", label: "" }]}
              />
            </div>
          </Option>
          <Option
            title="Remember Export Fields"
            description="Save checked fields for the next session"
          >
            <div className="flex flex-col gap-3">
              <Check
                name="remember_fields"
                options={[{ value: "true", label: "" }]}
              />
            </div>
          </Option>
          <button
            type="submit"
            className="mt-4 rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium text-white"
          >
            Export
          </button>
        </form>
      </FormProvider>
    </PageContainer>
  );
};

export default ExportCsv;
