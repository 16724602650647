import React, { useState, useEffect, FC } from "react";
import { Controller, useFormState } from "react-hook-form";
import { FormInputProps } from "../../../types/FormInputsProps";

const styleInput = {
  input:
    "h-24 w-full rounded-lg border border-colorBorder placeholder-black-100 pt-6 pl-8 font-bold text-black-100 focus:border-none focus:outline-none  focus:ring-colorBorder",
  dropdown:
    "absolute w-full bg-white border border-gray-300 mt-1 rounded-lg z-10 max-h-80 overflow-y-auto",
  option: "p-2 hover:bg-gray-100/10 cursor-pointer  even:bg-gray-50/50",
};

const FormInput: FC<FormInputProps> = ({
  name,
  options,
  label,
  placeholder,
  divClass = "relative w-full mb-6",
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options || []);
  const [selected, setSelected] = useState<string | null>(null);
  const formState = useFormState();

  useEffect(() => {
    const filtered = options?.filter((opt) =>
      opt.text.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredOptions(filtered || []);
  }, [searchTerm, options]);

  return (
    <Controller
      name={name}
      render={({ field }) => {
        const handleOptionClick = (option: { value: string; text: string }) => {
          field.onChange(option?.value);
          setSelected(option?.text);
          setSearchTerm("");
          setShowDropdown(false);
        };
        return (
          <div className={divClass}>
            <p className="absolute left-8 top-4 font-semibold text-gray-100">
              {label}
            </p>
            <input
              type="text"
              className={styleInput.input}
              placeholder={selected || placeholder || label}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onFocus={() => setShowDropdown(true)}
              onBlur={() => {
                // Delay hiding dropdown to allow option click to register
                setTimeout(() => setShowDropdown(false), 200);
              }}
            />
            {showDropdown && (
              <div className={styleInput.dropdown}>
                {filteredOptions.map((option, i) => (
                  <div
                    className={styleInput.option}
                    key={i}
                    onClick={() => handleOptionClick(option)}
                  >
                    {option.text ? option.text : option}
                  </div>
                ))}
              </div>
            )}
            {formState.errors[name] && (
              <p className="text-red-600 dark:text-red-500 mt-2 text-sm text-red">
                {String(
                  formState.errors[name] ? formState.errors[name]?.message : ""
                )}
              </p>
            )}
          </div>
        );
      }}
    />
  );
};

export default FormInput;
