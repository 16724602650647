import { Row } from "@tanstack/react-table";
import {
  DotsHorizontalIcon,
  Pencil1Icon,
  Pencil2Icon,
  TrashIcon,
} from "@radix-ui/react-icons";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Button } from "../ui/button";

interface DataTableRowActionsProps<TData> {
  row: Row<TData>;
  onEditPrice: (id: string) => void;
  onDelete: (id: string) => void;
  onSeeTraceability: (order_ticket_id: string) => void;
}

export const DataTableRowActions = <TData,>({
  row,
  onEditPrice,
  onDelete,
  onSeeTraceability,
}: DataTableRowActionsProps<TData>) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
        >
          <DotsHorizontalIcon className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem
          className="flex cursor-pointer gap-1"
          onClick={() => onEditPrice(row.getValue("id"))}
        >
          <Pencil1Icon />
          Editar Precio
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          className="flex cursor-pointer gap-1"
          onClick={() => onDelete(row.getValue("id"))}
        >
          <TrashIcon />
          Eliminar Oferta
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          className="flex cursor-pointer gap-1"
          onClick={() => onSeeTraceability(row.getValue("id"))}
        >
          <Pencil2Icon />
          Ver trazabilidad del ticket
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
