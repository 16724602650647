import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { GrClose } from "react-icons/gr";
import { StateContext } from "../../../context/StateProvider";
import { importCSV } from "../../../services/rrppService";
import { ItemForLoad } from "./ItemForLoad";
import jsonToFormData from "@ajoelp/json-to-formdata";

export const PopUpImportCsv = ({}) => {
  const { state, dispatch } = useContext(StateContext);
  const [filesDetail, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileName, setFileName] = useState("");

  const hideImportCsv = () => {
    dispatch({ type: "importCsv", payload: null });
  };

  useEffect(() => {
    document.getElementById("upload_files")?.click();
  }, []);

  const onChangeFiles = async (e: any) => {
    e.preventDefault();
    // console.log('FILES',e.target.files);
    // console.log('DETAIL', e.target.files[0].name);
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    let newItems = [];

    for (let index = 0; index < files.length; index++) {
      // const reader = new FileReader();

      // reader.onload = (event) => {
      //   console.log('ITEM X', event.target?.result);
      // }

      // reader.readAsText(files[index]);

      // const newItem = <ItemForLoad file_name={files[index].name} />
      newItems.push({
        file: files[index],
        //name: files[index].name,
        progress: uploadProgress,
        total: 0,
        seller: 0,
        new_mails: 0,
        size_file: 0,
        completed: false,
        errors: [],
        loading: true,
      });
    }

    setFiles(newItems);

    //setFiles(files);
  };

  const importCsvFile = async () => {
    // console.log('ENTRO ACA');
    //console.log(filesDetail![0]);
    //setUploadProgress(0);
    let producer_id = state.params.producer_id;
    if (filesDetail && filesDetail.length > 0) {
      for (let index = 0; index < filesDetail.length; index++) {
        setFileName(filesDetail[index].file.name);
        await importCSV(
          jsonToFormData({ file: filesDetail[index].file }),
          { producer_id: producer_id },
          {
            onUploadProgress: (progressEvent: any) => {
              let percentageUpload =
                (progressEvent.loaded / progressEvent.total) * 100;
              if (percentageUpload > 90) percentageUpload = 90;
              setUploadProgress(percentageUpload);
              // console.log(uploadProgress);
              // console.log('WWW', (progressEvent.loaded / progressEvent.total) * 100);
            },
          }
        )
          .then((response) => {
            console.log(response.data);
            setFiles((currentState) => {
              const item = [...currentState];
              item[index].completed = true;
              item[index].total = response.data.totalRows;
              item[index].seller = response.data.sellers;
              item[index].new_mails = response.data.newUsers;
              item[index].loading = false;
              return item;
            });
          })
          .catch((error) => {
            console.log(error);
            if (error.response.data.error == "invalid sellers") {
              setFiles((currentState) => {
                const item = [...currentState];
                item[index].completed = true;
                item[index].total = 0;
                item[index].seller = 0;
                item[index].new_mails = 0;
                item[index].errors = error.response.data.message;
                return item;
                item[index].loading = false;
              });
            }
            if (error.response.data.error == "archivo inválido") {
              setFiles((currentState) => {
                const item = [...currentState];
                item[index].completed = true;
                item[index].total = 0;
                item[index].seller = 0;
                item[index].new_mails = 0;
                item[index].errors = error.response.data.message;
                return item;
                item[index].loading = false;
              });
            }
          });
      }
      //filesDetail[0].progress = 50;
    } else {
      document.getElementById("upload_files")?.click();
    }
    // console.log('FILES!!!', filesDetail);
  };

  return (
    <div
      className="fixed  z-50 flex h-screen w-screen items-center justify-center bg-black-100/30 backdrop-blur-md"
      style={{ minHeight: "calc(100vh)" }}
    >
      <div
        className="absolute top-20 m-auto flex flex-col justify-center rounded-2xl bg-white "
        style={{ height: "calc(100vh - 160px)" }}
      >
        <div className="flex w-full justify-between border-b border-colorBorder px-8  pb-4 pt-5 text-center">
          <h3 className=" text-lg font-bold">Importar archivos CSV</h3>
          <button className="" onClick={hideImportCsv}>
            <GrClose />
          </button>
        </div>

        <div className="flex-grow">
          {/* {files.map((file: File, index) => (
            <div key={index}>{component}</div>
            <ItemForLoad file_name={file.name} />
          ))} */}
          {Array.from(filesDetail).map((item: any) => (
            <ItemForLoad
              completed={item.completed}
              active_file_name={fileName}
              file_name={item.file.name}
              progress={uploadProgress}
              size_file={item.file.size / 1000}
              total={item.total}
              seller={item.seller}
              new_mails={item.new_mails}
              errors={item.errors}
              loading={item.loading}
            />
          ))}
        </div>

        <div className="flex w-full justify-between border-colorBorder px-8  pb-4 pt-5 text-center ">
          <button
            className="m-auto flex items-center gap-2 rounded-full border-[2px] border-black-100 px-12 py-2 font-bold text-black-100 hover:bg-black-100 hover:fill-white hover:text-white"
            onClick={importCsvFile}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.25 17C2.25 19.6234 4.37665 21.75 7 21.75L17 21.75C19.6234 21.75 21.75 19.6234 21.75 17L21.75 13C21.75 10.3766 19.6234 8.25 17 8.25L16.5 8.25C16.0858 8.25 15.75 8.58579 15.75 9C15.75 9.41421 16.0858 9.75 16.5 9.75L17 9.75C18.7949 9.75 20.25 11.2051 20.25 13L20.25 17C20.25 18.7949 18.7949 20.25 17 20.25L7 20.25C5.20507 20.25 3.75 18.7949 3.75 17L3.75 13C3.75 11.2051 5.20507 9.75 7 9.75L7.5 9.75C7.91421 9.75 8.25 9.41421 8.25 9C8.25 8.58579 7.91421 8.25 7.5 8.25L7 8.25C4.37665 8.25 2.25 10.3766 2.25 13L2.25 17ZM8.46967 5.46967C8.17678 5.76256 8.17678 6.23744 8.46967 6.53033C8.76256 6.82322 9.23744 6.82322 9.53033 6.53033L11.25 4.81066L11.25 15C11.25 15.4142 11.5858 15.75 12 15.75C12.4142 15.75 12.75 15.4142 12.75 15L12.75 4.81066L14.4697 6.53033C14.7626 6.82322 15.2374 6.82322 15.5303 6.53033C15.8232 6.23744 15.8232 5.76256 15.5303 5.46967L12.5303 2.46967C12.2374 2.17678 11.7626 2.17678 11.4697 2.46967L8.46967 5.46967Z"
                fill="black"
              />
            </svg>
            Importar CSV
          </button>
        </div>

        <input
          type="file"
          accept=".csv"
          className="hidden"
          id="upload_files"
          name="files"
          onChange={(e) => onChangeFiles(e)}
          multiple
        />
      </div>
    </div>
  );
};
