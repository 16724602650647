import { FC, useState, Dispatch, SetStateAction } from "react";

import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import { usePagination } from "../../hooks/use-pagination";
import { IFilterRequest } from "../../types/IFilter";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { updateUrl } from "../../utils/updateUrl";

interface FilterPageProps {
  filters: IFilterRequest;
  setFilters: Dispatch<SetStateAction<IFilterRequest>>;
  totalPages: number;
}

export const FilterPage: FC<FilterPageProps> = ({
  filters,
  setFilters,
  totalPages,
}) => {
  const { page: currentPage = 1 } = filters;

  const [pageNumberInput, setPageNumberInput] = useState<number>(currentPage);

  const { intermediatePages, showInitialDots, showFinalDots } = usePagination({
    currentPage,
    totalPages,
  });

  const handlePageNumberChange = (evt: React.ChangeEvent<HTMLInputElement>) =>
    setPageNumberInput(+evt.target.value);

  const handleChangePage = (newPage: number) => {
    updateUrl({ page: newPage, query: filters.query });
    setFilters({ ...filters, page: newPage });
  };

  return (
    <Pagination className="block">
      <PaginationContent>
        <PaginationItem className="cursor-pointer">
          <PaginationPrevious
            onClick={
              currentPage !== 1
                ? () => handleChangePage(currentPage - 1)
                : undefined
            }
            aria-disabled={currentPage <= 1}
            tabIndex={currentPage <= 1 ? -1 : undefined}
            className={
              currentPage <= 1 ? "pointer-events-none opacity-50" : undefined
            }
          />
        </PaginationItem>

        <PaginationItem
          className="cursor-pointer"
          onClick={currentPage !== 1 ? () => handleChangePage(1) : () => {}}
        >
          <PaginationLink
            className="w-fit px-3"
            onClick={currentPage !== 1 ? () => handleChangePage(1) : undefined}
            isActive={currentPage === 1}
          >
            1
          </PaginationLink>
        </PaginationItem>

        {showInitialDots && (
          <PaginationItem>
            <PaginationEllipsis />
          </PaginationItem>
        )}

        {intermediatePages.map((page) => (
          <PaginationItem
            key={page}
            className="cursor-pointer"
            onClick={
              currentPage !== page
                ? () => handleChangePage(page as number)
                : undefined
            }
          >
            <PaginationLink
              className="w-fit px-3"
              isActive={currentPage === page}
            >
              {page}
            </PaginationLink>
          </PaginationItem>
        ))}

        {showFinalDots && (
          <PaginationItem>
            <PaginationEllipsis />
          </PaginationItem>
        )}

        {totalPages > 1 && (
          <PaginationItem
            className="cursor-pointer"
            onClick={
              currentPage !== totalPages
                ? () => handleChangePage(totalPages)
                : undefined
            }
          >
            <PaginationLink
              className="w-fit px-3"
              isActive={currentPage === totalPages}
            >
              {totalPages}
            </PaginationLink>
          </PaginationItem>
        )}

        <PaginationItem className="cursor-pointer">
          <PaginationNext
            onClick={
              currentPage !== totalPages
                ? () => handleChangePage(currentPage + 1)
                : undefined
            }
            aria-disabled={currentPage >= totalPages}
            tabIndex={currentPage >= totalPages ? -1 : undefined}
            className={
              currentPage >= totalPages
                ? "pointer-events-none opacity-50"
                : undefined
            }
          />
        </PaginationItem>
      </PaginationContent>

      {totalPages > 6 && (
        <div className="mt-3 flex justify-center gap-3">
          <Input
            type="number"
            placeholder=""
            min={1}
            max={totalPages}
            value={pageNumberInput}
            className="w-20"
            onChange={handlePageNumberChange}
          />
          <Button onClick={() => handleChangePage(pageNumberInput)}>Ir</Button>
        </div>
      )}
    </Pagination>
  );
};
