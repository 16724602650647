import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import Skeleton from "./OffersDetailsSkeleton";
import { PageContainer } from "../PageContainer";
import { Back } from "../Back";
import OfferService from "@/services/offerService";
import { DetailsInfo } from "../DetailsInfo/DetailsInfo";
import {
  getOfferDetailsFields,
  getOrderDetailsFields,
  getTransactionDetailsFields,
} from "./utils/getDetailsFields";

export const OfferDetails = () => {
  const navigate = useNavigate();
  const { offer_id } = useParams();

  const { data: offerData, isFetching: isFetchingOffer } = useQuery({
    queryKey: ["offer", offer_id],
    queryFn: () => OfferService.get(offer_id!),
  });

  const orderDetailsFields = getOrderDetailsFields(offerData);
  const offerDetailsFields = getOfferDetailsFields(offerData);
  const transactionDetailsFields = getTransactionDetailsFields(offerData);

  console.log(offerData);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBack = () =>
    window.history.length > 2 ? navigate(-1) : navigate("/admin/offers");

  const pageContent = isFetchingOffer ? (
    <Skeleton />
  ) : (
    <>
      <DetailsInfo
        sectionTitle="Detalle de la orden"
        fallbackMessage="No hay una orden asociada a esta oferta."
        fields={orderDetailsFields}
      />

      <DetailsInfo
        sectionTitle="Detalle de la oferta"
        fields={offerDetailsFields}
      />

      <DetailsInfo
        sectionTitle="Detalle de la operación"
        fallbackMessage="El ticket aún no ha sido vendido."
        fields={transactionDetailsFields}
      />
    </>
  );

  return (
    <PageContainer>
      <div className="mb-3">
        <Back onClick={handleBack} />
      </div>
      <h1 className="text-center font-sans text-[20px] font-[700] leading-[32px] tracking-[-0.03em]">
        Detalle de Oferta #{offer_id}
      </h1>

      {pageContent}
    </PageContainer>
  );
};
