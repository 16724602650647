import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { motion } from "framer-motion";

import { Back } from "../../../Back";
import { Title } from "../../../events/Title";
import { formatDateTime } from "../../../../utils/format-date";
import { IOrderTicketLogResponse } from "../../../../types/IOrderTicketLog";
import TicketService from "../../../../services/ticketService";
import TableCellLink from "../../../FormElement/TableCellLink";

const columnData = ["Fecha", "Envía", "Recibe", "Sent Status"];

export const Traceability = () => {
  const { order_ticket_id } = useParams();
  const navigate = useNavigate();

  const [result, setResult] = useState<IOrderTicketLogResponse[]>([]);

  const getItems = useMutation(() =>
    TicketService.getTraceability(order_ticket_id || "")
  );

  useEffect(() => {
    getItems.mutateAsync().then((res) => setResult(res));
  }, []);

  return (
    <div>
      {window.history.length > 1 && <Back onClick={() => navigate(-1)} />}
      <div className="mt-5">
        <Title>Trazabilidad del ticket ID {order_ticket_id}</Title>
      </div>
      <table className="my-table-spacing h-full w-full border-separate border-spacing-y-2 gap-5 overflow-x-auto pt-5 text-left">
        <thead className=" divide-solid border ">
          <tr className=" pb-4 text-sm font-bold text-gray-100">
            {columnData.map((column, index) => (
              <th
                className="border-b-[1px] border-colorBorder pb-4"
                key={column + index}
              >
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {result &&
            result.length > 0 &&
            result.map((row: IOrderTicketLogResponse, i: number) => (
              <motion.tr
                key={i}
                layout
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                initial={{ opacity: 0.2 }}
                transition={{ type: "spring", stiffness: 100, bounce: 0 }}
                className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
              >
                <td className="pl-4">{formatDateTime(row.createdAt)}</td>
                <TableCellLink
                  href={row.sender?.id ? `/admin/users/${row.sender.id}` : ""}
                  className={row.sender?.id ? "text-blue-500 underline" : ""}
                >
                  {row.sender?.full_name}
                </TableCellLink>
                <TableCellLink
                  href={
                    row.receiver?.id ? `/admin/users/${row.receiver.id}` : ""
                  }
                  className={row.receiver?.id ? "text-blue-500 underline" : ""}
                >
                  {row.receiver?.full_name || row?.email || ""}
                </TableCellLink>
                <td>
                  {row.sent_status === "received" ? "Recibido" : "Pendiente"}
                </td>
              </motion.tr>
            ))}
          {result?.length == 0 && (
            <p className="text-center">No hay envíos para mostrar.</p>
          )}
        </tbody>
      </table>
    </div>
  );
};
