import { IEventResponse } from "../types/IEvent";

const statuses = [
  { value: "active", label: "Venta Activa" },
  { value: "activo", label: "Venta Activa" },
  { value: "pending", label: "pendiente" },
  { value: "pendiente", label: "Pendiente aprobación" },
  { value: "paused", label: "pausado" },
  { value: "send", label: "enviado" },
  { value: "sent", label: "enviado" },
  { value: "pending_approval", label: "requiere aprobación" },
  { value: "finished", label: "finalizado" },
];

const paymentStatuses = [
  { value: "active", label: "Pago Aprobado" },
  { value: "approved", label: "Pago Aprobado" },
  { value: "success", label: "Pago Aprobado" },
  { value: "pending", label: "Pendiente de aprobacion" },
  { value: "rejected", label: "Rechazado" },
  { value: "in_process", label: "en proceso" },
  { value: "refunded", label: "Reembolsado" },
];

const ticketStatuses = [
  { value: "used", label: "canjeado" },
  { value: "pending", label: "pendiente" },
];

const userStatuses = [
  { value: "active", label: "Activo" },
  { value: "paused", label: "Pausado" },
  { value: "pending", label: "Pendiente de Aprobación" },
  { value: "deleted", label: "Eliminado" },
];

export const offerStatuses = [
  { value: "active", label: "Activo" },
  { value: "paused", label: "Pausado" },
  { value: "in_progress", label: "En Progreso" },
  { value: "sold", label: "Vendido" },
  { value: "deleted", label: "Eliminado" },
];

export function getStatusFromValue(status: string) {
  let result = statuses.find((i) => i.value === status);
  if (!result) {
    result = { value: status, label: "Esperando aprobación" };
  }
  return result.label;
}

export function getPaymentStatusFromValue(status: string) {
  const result = paymentStatuses.find((i) => i.value === status);
  return result ? result.label : status;
}

export function getTicketStatusFromValue(status: string) {
  const result = [...ticketStatuses, ...paymentStatuses].find(
    (i) => i.value === status
  );
  return result ? result.label : status;
}

export function getUserStatusFromValue(status: string) {
  const result = userStatuses.find((i) => i.value === status);
  return result ? result.label : status;
}

export const getEventStatus = (item: IEventResponse) => {
  if (item.temp && item.status != "finished") return "pending_approval";
  return item.status;
};

export function getOfferStatusFromValue(status: string) {
  const result = offerStatuses.find((i) => i.value === status);
  return result ? result.label : status;
}

export const getBgColorFromValue = (status: string) =>
  ["approved", "success", "active"].includes(status)
    ? "bg-green"
    : ["pending", "paused", "in_progress"].includes(status)
    ? "bg-orange"
    : "bg-red";
