import { useSearchParams } from "react-router-dom";

import config from "../config/variables";

export const getUrlParams = () => {
  const [searchParams] = useSearchParams();
  const page =
    searchParams.get("page") !== null ? +searchParams.get("page")! : 1;

  const limit =
    searchParams.get("limit") !== null
      ? +searchParams.get("limit")!
      : config.filter_default_limit;

  const query =
    searchParams.get("query") !== null ? searchParams.get("query")! : "";

  const sortBy =
    searchParams.get("sortBy") !== null
      ? searchParams.get("sortBy")!
      : "createdAt";

  const sortOrder =
    searchParams.get("sortOrder") !== null
      ? searchParams.get("sortOrder")!
      : "desc";

  return { page, limit, query, sortBy, sortOrder };
};
