import { FC, useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";

import editar from "../../../../assets/editar.svg";
import arrow from "../../../../assets/arrow-right.svg";
import copiarPegar from "../../../../assets/copiar-pegar.svg";
import alarm from "../../../../assets/alarm.svg";
import migrar from "../../../../assets/migrar.svg";
import agregar from "../../../../assets/agregar.svg";
import borrar from "../../../../assets/borrar.svg";
import { ITicketResponse } from "../../../../types/ITicket";
import { formatMoney2 } from "../../../../utils/format-money";
import { getPaymentTypeFromValue } from "../../../../utils/payments";
import { AuthContext } from "../../../../context/AuthContext";
import { StateContext } from "../../../../context/StateProvider";
import TicketService from "../../../../services/ticketService";
import { Status } from "../../../Status";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import EventService from "@/services/eventService";
import { IEventResponse } from "@/types/IEvent";
import ReactTooltip from "react-tooltip";

type typeProps = {
  handleFormDisplay: (id: string) => void;
  loadItems: () => void;
  data: ITicketResponse;
  client_id: string;
  event_id: string;
  event_name: string;
};

export const CardTicket: FC<typeProps> = ({
  handleFormDisplay,
  loadItems,
  data,
  client_id,
  event_id,
  event_name,
}) => {
  const [eventTargetInputId, setEventTargetInputId] = useState<string>("");
  const [eventTarget, setEventTarget] = useState<IEventResponse | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const styles = {
    h3: "font-bold text-lg",
    p: "text-gray-100",
  };
  const { role, permissions } = useContext(AuthContext);

  const {
    data: eventData,
    isFetching: isFetchingEvent,
    refetch: refetchEvent,
  } = useQuery({
    queryKey: ["event", eventTargetInputId],
    queryFn: () => EventService.get(eventTargetInputId),
    enabled: false,
    onSuccess: handleEventDataValidation,
    onError: handleEventError,
  });

  const handleSendRecycler = (id: string) => {
    recyclerItems.mutateAsync(id).then((res) => loadItems());
  };

  const recyclerItems = useMutation(
    (id: string) => TicketService.sendRecycler(id),
    {
      onSuccess(data: any) {
        dispatch({
          type: "toastSuccess",
          payload: "El Ticket fue enviado a la papelera de reciclaje.",
        });
      },
    }
  );

  const migrateTickets = useMutation(() =>
    TicketService.migrateTickets(data.id, event_id, eventTargetInputId)
  );

  const { dispatch } = useContext(StateContext);

  const handleValidation = (
    id: string,
    stock: number,
    total: number,
    type: boolean
  ) => {
    if (stock < total) {
      dispatch({
        type: "toastError",
        payload:
          "Existen tickets vendidos !!! Usted no puede editar o eliminar los datos.",
      });
    } else {
      if (type) {
        handleFormDisplay(data.id);
      } else {
        if (
          window.confirm("Esta seguro de enviar a la papelera este ticket?")
        ) {
          handleSendRecycler(data.id);
        }
      }
    }
  };

  const handleValidateEventTarget = async () => {
    if (eventTargetInputId === event_id) {
      dispatch({
        type: "toastError",
        payload: "El evento elegido coincide con el evento actual",
      });
      return;
    }
    await refetchEvent();
  };

  function handleEventError() {
    dispatch({
      type: "toastError",
      payload: "No encontramos un evento con este id",
    });
  }

  function handleEventDataValidation(data: IEventResponse | undefined) {
    if (data?.producer?.client_id !== client_id) {
      dispatch({
        type: "toastError",
        payload: "El evento elegido no corresponde al mismo cliente",
      });
      return;
    }

    setEventTarget(data);
  }

  const handleMigrate = () => {
    setIsDialogOpen(false);
    dispatch({ type: "showLoaderScreen", payload: true });
    migrateTickets.mutateAsync().then((res) => {
      dispatch({ type: "showLoaderScreen", payload: false });
      dispatch({
        type: "toastSuccess",
        payload: "Migración completada con éxito",
      });
      loadItems();
    });
  };

  const handleCloseModal = () => {
    setEventTargetInputId("");
    setEventTarget(null);
  };

  const handleClipboard = async () => {
    const value = await navigator.clipboard.readText();
    setEventTargetInputId(value);
  };

  console.log(eventData);

  const dialogContent = (
    <div className="flex flex-col justify-center p-6">
      {!eventTarget ? (
        <>
          <label htmlFor="event-migration" className="mb-2 text-center">
            Pegá el ID del evento al cual migrar el lote
          </label>
          <div className="mb-4 flex items-center justify-between gap-2">
            <Input
              id="event-migration"
              name="event-migration"
              placeholder="Id del evento..."
              value={eventTargetInputId}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                setEventTargetInputId(evt.target.value)
              }
              className="grow"
            />
            <button onClick={handleClipboard}>
              <img
                className="h-[20px] w-[20px] cursor-pointer"
                src={copiarPegar}
                data-tip
                data-for="paste"
              />
            </button>
            <ReactTooltip id="paste" className="whitespace-nowrap">
              Pegar del portapapeles
            </ReactTooltip>
          </div>
          <div className="mt-10 flex justify-center">
            <Button
              type="button"
              onClick={handleValidateEventTarget}
              disabled={eventTargetInputId === "" || isFetchingEvent}
            >
              {isFetchingEvent ? "Buscando..." : "Continuar"}
            </Button>
          </div>
        </>
      ) : (
        <>
          <p className="text-center">
            Vas a migrar{" "}
            <strong>{`
            ${data?.tickets_sold} ticket${
              data?.tickets_sold === 1 ? "" : "s"
            }`}</strong>{" "}
            del evento actual al siguiente evento:
          </p>
          <div className="my-6 grid grid-cols-[1fr_50px_1fr]">
            <p className="rounded-xl border border-gray-100 p-4 leading-5">
              {event_name}
            </p>
            <div className="grid place-items-center">
              <img className="h-[20px] w-[20px] cursor-pointer" src={arrow} />
            </div>
            <p className="rounded-xl border border-gray-100 p-4 leading-5">
              {eventData?.name}
            </p>
          </div>
          <div className="flex justify-center gap-2">
            <Button type="button" onClick={handleCloseModal}>
              Atrás
            </Button>
            <Button type="button" onClick={handleMigrate}>
              Confirmar migración
            </Button>
          </div>
        </>
      )}
    </div>
  );

  return (
    <div className="flex justify-between rounded-xl px-5 pb-7 pt-8 outline outline-2 outline-colorBorder">
      <section className="flex w-[70%] flex-col gap-5">
        <article className="">
          <h3 className={styles.h3}>{data.name}</h3>
          <p className={styles.p}> {data.description} </p>
        </article>
        <article className="flex gap-20">
          <div>
            <h3 className={styles.h3}>{formatMoney2(data.price)} </h3>
            <p className={styles.p}>Precio</p>
          </div>
          <div>
            <h3 className={styles.h3}>
              {data.available_stock < 0 ? 0 : data.available_stock} /{" "}
              {data.stock}
            </h3>
          </div>
        </article>
        {data.type && data.type !== "Free" && (
          <div>
            <h3 className={styles.h3}>
              {getPaymentTypeFromValue(data.payment_type || "all")}
            </h3>
            <p className={styles.p}>Medio de pago</p>
          </div>
        )}
        {data?.status === "pending_approval" && (
          <div className="mr-2 pt-2">
            <Status status={data.status} />
          </div>
        )}
      </section>
      <div className="flex items-baseline gap-1">
        {role === "admin" &&
          permissions.admin_access === "super_admin" &&
          data.soldout &&
          data.type !== "Free" && (
            <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
              <DialogTrigger>
                <button type="button" data-tip data-for="migrate">
                  <img
                    className="h-[20px] w-[20px] cursor-pointer"
                    src={migrar}
                  />
                </button>
                <ReactTooltip id="migrate">Migrar Lote</ReactTooltip>
              </DialogTrigger>
              <DialogContent onCloseAutoFocus={handleCloseModal}>
                <DialogHeader>
                  <DialogTitle className="text-center text-3xl">
                    Migrar Lote
                  </DialogTitle>
                </DialogHeader>

                {dialogContent}
              </DialogContent>
            </Dialog>
          )}
        <NavLink to={`?section=tickets&ticket_id=${data.id}`}>
          <img
            className="h-[20px] w-[20px] cursor-pointer"
            data-for="edit-ticket"
            data-tip
            src={
              data?.status === "pending_approval" && role === "admin"
                ? alarm
                : editar
            }
            onClick={() => handleFormDisplay(data.id)}
          />
          <ReactTooltip id="edit-ticket">Editar ticket</ReactTooltip>
        </NavLink>
        <NavLink to={`?section=tickets&ticket_id=${data.id}&action=duplicate`}>
          <img
            className="h-[20px] w-[20px] cursor-pointer"
            data-for="duplicate-ticket"
            data-tip
            src={agregar}
            onClick={() => handleFormDisplay(data.id)}
          />
          <ReactTooltip id="duplicate-ticket">Duplicar tickets</ReactTooltip>
        </NavLink>
        <img
          className="h-[20px] w-[20px] cursor-pointer"
          data-for="delete-ticket"
          data-tip
          src={borrar}
          onClick={() =>
            handleValidation(data.id, data.available_stock, data.stock, false)
          }
        />
        <ReactTooltip id="delete-ticket">Eliminar tickets</ReactTooltip>
      </div>
    </div>
  );
};
