import { useEffect, useContext } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useMutation } from "@tanstack/react-query";
import * as yup from "yup";
import jsonToFormData from "@ajoelp/json-to-formdata";

import FormInput from "../FormElement/FormInput";
import { IConfigRequest } from "../../types/IConfig";
import { setFormErrorsFromServer } from "../../utils/form";
import ConfigService from "../../services/configService";
import { StateContext } from "../../context/StateProvider";
import FormToggle from "../FormToggle";
import FormSelectMultiple from "../FormElement/FormSelectMultiple";

const schema = yup.object().shape({
  // service_amount: yup.string().required("Campo requerido"),
  disabled_words: yup.array().of(
    yup.object().shape({
      value: yup.string(),
      label: yup.string(),
    })
  ),
  enable_attends: yup.bool(),
  enable_comments: yup.bool(),
  enable_notifications: yup.bool(),
  enable_swap: yup.bool(),
  use_cache: yup.bool(),
  regret_form_url: yup.string().optional(),
});

const defaultValues = {
  // service_amount: 0,
  disabled_words: [],
  enable_attends: true,
  enable_comments: true,
  enable_notifications: true,
  enable_swap: true,
  use_cache: true,
  regret_form_url: "",
};

export const FormConfig = ({}) => {
  const form = useForm<IConfigRequest>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const { state, dispatch } = useContext(StateContext);

  const getItem = useMutation(() =>
    ConfigService.get("643abe19afbfc082173a2016")
  );
  const updateItems = useMutation((item: FormData) =>
    ConfigService.update("643abe19afbfc082173a2016", item)
  );

  const onSubmit = async (data: IConfigRequest) => {
    let formData = jsonToFormData(
      {
        ...data,
        disabled_words: data.disabled_words?.map((el) => el.value),
      },
      {
        arrayIndexes: false,
        excludeNull: true,
      }
    );
    dispatch({ type: "showLoaderScreen", payload: true });

    updateItems
      .mutateAsync(formData)
      .then((res) => {
        console.log("🚀 ~ .then ~ res:", res);
        dispatch({ type: "showLoaderScreen", payload: false });
        dispatch({
          type: "toastSuccess",
          payload: "Configuraciones actualizadas",
        });
      })
      .catch((reason) => {
        setFormErrorsFromServer(reason, form.setError);
      });
  };

  useEffect(() => {
    getItem.mutateAsync().then((res) => {
      const disabledWordsMapped = res?.disabled_words?.map((el) => ({
        value: el,
        label: el,
      }));
      form.setValue("disabled_words", disabledWordsMapped);
      form.setValue("enable_attends", res.enable_attends);
      form.setValue("enable_comments", res.enable_comments);
      form.setValue("enable_notifications", res.enable_notifications);
      form.setValue("use_cache", res.use_cache);
      form.setValue("regret_form_url", res.regret_form_url);
    });
  }, [form.reset]);

  const onError = (error: any) => {
    console.log("error ", error);
  };

  return (
    <div className="flex flex-col gap-10 px-[20%] xl:px-[23rem]">
      <h1 className="text-center text-3xl font-bold">Configuraciones</h1>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit, onError)}
          className="flex flex-col gap-10"
        >
          <div className="flex justify-center gap-10 ">
            <FormToggle
              name="enable_comments"
              label="Habilitar Comentarios"
              color="green"
            />
          </div>
          <div className="flex justify-center gap-10 ">
            <FormToggle
              name="enable_attends"
              label="Habilitar Asistire"
              color="green"
            />
          </div>
          <div className="flex justify-center gap-10 ">
            <FormToggle
              name="enable_notifications"
              label="Habilitar Notificaciones"
              color="green"
            />
          </div>
          <div className="flex justify-center gap-10 ">
            <FormToggle
              name="enable_swap"
              label="Habilitar SWAP"
              color="green"
            />
          </div>
          <div className="flex justify-center gap-10 ">
            <FormToggle name="use_cache" label="Usar Caché" color="green" />
          </div>
          <div className="flex justify-center gap-10 ">
            <FormInput
              name="regret_form_url"
              type="text"
              label="Regret Form Url"
            />
          </div>
          <div className="flex justify-center gap-10 ">
            <FormSelectMultiple
              name="disabled_words"
              options={form.getValues("disabled_words")}
              label="Palabras Deshabilitadas"
              allowAddingOptions={true}
            />
          </div>
          <div className="mb-16 flex justify-center gap-10 pt-10">
            <button
              type="submit"
              className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium  text-white"
            >
              Guardar
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
