import { ComponentProps } from "react";
import FormSelectMultiple from "../../../../FormElement/FormSelectMultiple";

const SelectMultiple = ({
  name,
  options,
  allowSelectAll,
}: Omit<ComponentProps<typeof FormSelectMultiple>, "options"> & {
  options?: { value: string | number; label: string }[];
}) => {
  return (
    <span className="[&>:nth-child(1)]:mb-0 [&_select]:py-0">
      <FormSelectMultiple
        name={name}
        options={options}
        allowSelectAll={allowSelectAll}
      />
    </span>
  );
};

export default SelectMultiple;
